import { useContext, useEffect } from "react";
import toast from "react-hot-toast";
import AuthServices from "../../services/AuthServices";
import { UserContext } from "../../contexts/UserContext";
import useQuery from "../../hooks/useQuery";
import { useNavigate } from "react-router-dom";

export default function GoogleAuthorizationPage() {
  const query = useQuery();
  const { dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const authenticate = async () => {
      try {
        const signInAction = await AuthServices.googleLogin(query.get("token"));

        if (signInAction?.token) {
          localStorage.setItem("userInfo", JSON.stringify(signInAction?.user));
          localStorage.setItem("authToken", signInAction?.token);
          toast.success(signInAction?.message);
          dispatch({ type: "USER_LOGIN", payload: signInAction?.user });
        } else {
          toast.error("An error occurred while authenticating");
          navigate("/auth/sign-in");
        }
      } catch (error) {
        if (error?.response?.data) toast.error(error?.response?.data?.message);
        else toast.error("An error occurred while authenticating");

        navigate("/auth/sign-in");
      }
    };

    authenticate();
  }, [query]);

  return (
    <>
      <main className="grid min-h-screen place-items-center bg-gray-900 px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-blue-600">
            Authentication granted
          </p>
          <div className="flex justify-center items-center">
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-200 sm:text-5xl">
              Authorizing
            </h1>
            <div className="mt-8 ml-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                stroke="#000"
              >
                <style>
                  {
                    "@keyframes spinner_zKoa{to{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,to{stroke-dasharray:42 150;stroke-dashoffset:-59}}"
                  }
                </style>
                <g
                  style={{
                    transformOrigin: "center",
                    animation: "spinner_zKoa 2s linear infinite",
                  }}
                >
                  <circle
                    cx={12}
                    cy={12}
                    r={9.5}
                    fill="none"
                    stroke="white"
                    strokeWidth={3}
                    style={{
                      strokeLinecap: "round",
                      animation: "spinner_YpZS 1.5s ease-in-out infinite",
                    }}
                  />
                </g>
              </svg>
            </div>
          </div>

          <p className="mt-6 text-base leading-7 text-gray-200">
            Please wait while we redirect you to the portal
          </p>
        </div>
      </main>
    </>
  );
}
