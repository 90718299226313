import { PaperAirplaneIcon, PaperClipIcon } from "@heroicons/react/20/solid";

import { SparklesIcon } from "@heroicons/react/24/outline";
import classnames from "../utilities/classnames";

export default function InputBox({ handleSubmit, input, setInput, transcription }) {
	return (
		<form action="#" className="relative" onSubmit={(e) => handleSubmit(e)}>
			<div className="flex overflow-hidden gap-2 shadow-sm">
				{/* <button
					type="button"
					className="px-3 inline-flex items-center rounded-full bg-blue-600 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
				>
					<PaperClipIcon
						className="h-5 w-5 group-hover:text-gray-500"
						aria-hidden="true"
					/>
				</button> */}
				<SparklesIcon
					className="absolute top-3 text-gray-400 left-3 h-5 w-5 group-hover:text-gray-500"
					aria-hidden="true"
				/>
				<label htmlFor="title" className="sr-only">
					Send a message
				</label>
				<input
					type="text | file"
					name="title"
					id="title"
					value={input}
					onChange={(e) => setInput(e.target.value)}
					className="block w-full rounded-lg pt-2.5 pb-2.5 text-md placeholder:text-gray-500 placeholder:bg-transparent pl-10 pr-20"
					placeholder="Send a message"
				/>
				<button
					type="submit"
					className={classnames(
						input.trim().length === 0
							? "absolute right-1 top-1 inline-flex items-center rounded-md text-gray-300 px-2 py-2 text-sm font-semibold shadow-sm cursor-not-allowed"
							: "absolute right-1 top-1 inline-flex items-center rounded-md text-blue-600 px-2 py-2 text-sm font-semibold shadow-sm hover:text-blue-500"
					)}
				>
					<PaperAirplaneIcon
						className="h-5 w-5 group-hover:text-gray-500"
						aria-hidden="true"
					/>
				</button>
			</div>
		</form>
	);
}

