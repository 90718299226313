import { useContext, useState } from "react";
import classnames from "../../utilities/classnames";
import toast from "react-hot-toast";
import AuthServices from "../../services/AuthServices";
import { UserContext } from "../../contexts/UserContext";
import GoogleLogin from "react-google-login";
import { useGoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";

export default function SignInPage() {
  const [loading, setLoading] = useState(false);
  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });

  const { dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  const SubmitHandler = async () => {
    setLoading(true);
    try {
      if (!signInData.email) {
        toast.error('"Email" cannot be empty');
        setLoading(false);
        return;
      }

      if (!signInData.password) {
        toast.error('"Password" cannot be empty');
        setLoading(false);
        return;
      }

      const signInAction = await AuthServices.signIn(signInData);

      localStorage.setItem("userInfo", JSON.stringify(signInAction.user));
      localStorage.setItem("authToken", signInAction.access_token);
      dispatch({
        type: "USER_LOGIN",
        payload: signInAction.user,
      });

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          typeof error === "string"
            ? error
            : "Something went wrong, Please try again later"
        );
      }
      setLoading(false);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      navigate(`/auth/google?token=${tokenResponse.access_token}`);
    },
  });

  return (
    <>
      <div className="flex min-h-screen bg-gray-900 flex-1 flex-col justify-center px-6 py-8 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex items-center justify-center gap-2">
            <img
              className="h-10 w-auto"
              src="/chat-logo.svg"
              alt="Chatbot-23"
            />
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-white"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={signInData.email}
                  onChange={(changeEvent) =>
                    setSignInData({
                      ...signInData,
                      email: changeEvent.target.value,
                    })
                  }
                  disabled={loading}
                  autoComplete="email"
                  required
                  className={classnames(
                    loading
                      ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                      : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  )}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={signInData.password}
                  onChange={(changeEvent) =>
                    setSignInData({
                      ...signInData,
                      password: changeEvent.target.value,
                    })
                  }
                  disabled={loading}
                  autoComplete="current-password"
                  required
                  className={classnames(
                    loading
                      ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                      : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  )}
                />
              </div>
              <div className="flex items-center justify-end mt-2 ">
                <div className="text-sm">
                  <Link
                    to={`/auth/forgot-password?email=${signInData.email}`}
                    className="font-semibold text-blue-400 hover:text-blue-300"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
            </div>

            <div>
              <button
                disabled={loading}
                onClick={SubmitHandler}
                className={classnames(
                  loading
                    ? "flex w-full justify-center rounded-md bg-gray-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                    : "flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                )}
              >
                Confirm
              </button>
            </div>
          </div>

          <p className="mt-6 text-center text-sm text-gray-400">
            Not a user?{" "}
            <a
              href="/auth/sign-up"
              className="font-semibold leading-6 text-blue-400 hover:text-blue-300"
            >
              Sign up here
            </a>
          </p>
          <div className="mt-6">
            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-gray-900 px-6 text-white">Or</span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-4">
              <button
                onClick={handleGoogleLogin}
                className="flex w-full items-center justify-center gap-3 rounded-md bg-gray-100 px-3 py-1.5 text-gray-500 hover:text-gray-700 hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
              >
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                    <path
                      fill="#4285F4"
                      d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                    />
                    <path
                      fill="#34A853"
                      d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                    />
                    <path
                      fill="#FBBC05"
                      d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                    />
                    <path
                      fill="#EA4335"
                      d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                    />
                  </g>
                </svg>
                <span className="text-sm font-semibold leading-6">
                  Continue with Google
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
