import axios from "axios";
import toast from "react-hot-toast";

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_VOICE_ASK_API_BASE_URL}`,

	// responseType: 'stream',
	timeout: 500000,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
		//   'Content-Type': 'application/octet-stream',
	},
});



const responseBody = (response) => response.data;
// const responseBody = (response) => {
//   console.log("Response:", response);
//   return response.data;
// };

const errorBody = (error) => {
	if (error.response) {
		toast.error(error.response.data.message);
	} else {
		error.message && toast.error(error.message);
	}
	return {
		success: false,
	};
};

const openAiRequestsVoiceAskNissan = {
	get: (url, body, headers) =>
		instance.get(url, body, headers).then(responseBody).catch(errorBody),

	getx: (url) => instance.get(url).then(responseBody).catch(errorBody),

	post: (url, body) =>
		instance.post(url, body).then(responseBody).catch(errorBody),

	put: (url) => instance.put(url).then(responseBody).catch(errorBody),

	patch: (url, body) =>
		instance.patch(url, body).then(responseBody).catch(errorBody),

	delete: (url) => instance.delete(url).then(responseBody).catch(errorBody),
};

export default openAiRequestsVoiceAskNissan;
