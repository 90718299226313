import React from 'react';

const SoundWaveIcon = () => (
  <img
    src="/wave.gif"
    alt="Wave Animation"
    className="h-6 w-20" 
  />
);

export default SoundWaveIcon;
