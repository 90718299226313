/* eslint-disable jsx-a11y/no-redundant-roles */
import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import classnames from "./utilities/classnames";
import { Link, NavLink, Outlet } from "react-router-dom";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/20/solid";
import { UserContext } from "./contexts/UserContext";
import { toast } from "react-hot-toast";

import useSWR from "swr";
import UserService from "./services/UserServices";

const navigation = [
  // { name: "Projects", href: "#", icon: FolderIcon, current: false },
];

const bots = [
  {
    id: 1,
    name: "Ask your PDF",
    href: "bots/ask-your-pdf",
    initial: "A",
    current: true,
  },
  { id: 2, name: "Medichat", href: "bots/medichat", initial: "M" },
  { id: 3, name: "IABchat", href: "bots/iabchat", initial: "I" },
  {
    id: 4,
    name: "IAB চ্যাট",
    href: "bots/iabchat-bangla",
    initial: "I",
  },
  {
    id: 5,
    name: "IAB English V2",
    href: "bots/iabchat-english-v2",
    initial: "I",
  },
  {
    id: 6,
    name: "CMC Chatbot",
    href: "bots/cmcchat",
    initial: "C",
  },
  {
    id: 7,
    name: "Voice Ask Nissan Chatbot",
    href: "bots/vochat",
    initial: "C",
  },
];

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { dispatch, state } = useContext(UserContext);

  const [userId, setUserId] = useState(null);

  const [data, setData] = useState(null);
  // const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getChatbotByUserId(userId);

        if (response.length > 0) setData(response);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (userId) fetchData();
  }, [userId]);

  useEffect(() => {
    const user = localStorage.getItem("userInfo");
    const userId = JSON.parse(user)?.id;

    setUserId(userId);
  }, []);

  const logoutHandler = async () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userInfo");
    dispatch({ type: "USER_LOGOUT" });
  };

  return (
    <>
      <div className="h-screen bg-gray-900">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 xl:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="/chat-logo.svg"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <NavLink
                                  to={item.href}
                                  className={({ isActive }) =>
                                    classnames(
                                      isActive
                                        ? "bg-gray-800 text-white"
                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )
                                  }
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            Your bots
                          </div>
                          {isLoading ? (
                            <p className="text-white text-sm">Loading...</p>
                          ) : (
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                              <li>
                                <NavLink
                                  to="/general-bot"
                                  className={({ isActive }) =>
                                    classnames(
                                      isActive
                                        ? "bg-gray-800 text-white"
                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )
                                  }
                                >
                                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                    G
                                  </span>
                                  <span className="truncate">General Bot</span>
                                </NavLink>
                              </li>

                              {data?.map((bot) => (
                                <li key={bot?.id}>
                                  <NavLink
                                    to={/bots/ + bot.chatbot?.id}
                                    className={({ isActive }) =>
                                      classnames(
                                        isActive
                                          ? "bg-gray-800 text-white"
                                          : "text-gray-400 hover:text-white hover:bg-gray-800",
                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                      )
                                    }
                                  >
                                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                      {bot?.chatbot?.name?.charAt(0)}
                                    </span>
                                    <span className="truncate">
                                      {bot?.chatbot?.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                        <li className="-mx-6 mt-auto flex">
                          <button
                            onClick={logoutHandler}
                            className="h-full p-2 bg-red-900 hover:bg-red-600"
                          >
                            <ArrowLeftOnRectangleIcon
                              className="h-5 w-5 text-red-200"
                              aria-hidden="true"
                            />
                          </button>
                          <Link
                            to="settings/profile"
                            className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                          >
                            <span className="sr-only">Your profile</span>
                            <span aria-hidden="true">
                              {state ? state.userInfo.name : "Developer Mode"}
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="w-auto h-12 mt-10"
                src="/chat-logo.svg"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={({ isActive }) =>
                            classnames(
                              isActive
                                ? "bg-gray-800 text-white"
                                : "text-gray-400 hover:text-white hover:bg-gray-800",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )
                          }
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    Your bots
                  </div>
                  {isLoading ? (
                    <p className="text-white text-sm">Loading...</p>
                  ) : (
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      <li>
                        <NavLink
                          to="/general-bot"
                          className={({ isActive }) =>
                            classnames(
                              isActive
                                ? "bg-gray-800 text-white"
                                : "text-gray-400 hover:text-white hover:bg-gray-800",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )
                          }
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            G
                          </span>
                          <span className="truncate">General Bot</span>
                        </NavLink>
                      </li>
                      {data?.map((bot) => (
                        <li key={bot?.id}>
                          <NavLink
                            to={/bots/ + bot.chatbot?.id}
                            className={({ isActive }) =>
                              classnames(
                                isActive
                                  ? "bg-gray-800 text-white"
                                  : "text-gray-400 hover:text-white hover:bg-gray-800",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )
                            }
                          >
                            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                              {bot?.chatbot?.name?.charAt(0)}
                            </span>
                            <span className="truncate">
                              {bot?.chatbot?.name}
                            </span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
                <li className="-mx-6 mt-auto flex ">
                  <button
                    onClick={logoutHandler}
                    className="h-full p-2 bg-red-900 hover:bg-red-600"
                  >
                    <ArrowLeftOnRectangleIcon
                      className="h-5 w-5 text-red-200"
                      aria-hidden="true"
                    />
                  </button>
                  <Link
                    to="settings/profile"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                  >
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">
                      {state ? state.userInfo.name : "Developer Mode"}
                    </span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <Outlet context={[sidebarOpen, setSidebarOpen]} />
      </div>
    </>
  );
}
