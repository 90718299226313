import { useEffect, useState } from "react";
import classnames from "../../utilities/classnames";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import useQuery from "../../hooks/useQuery";
import AuthServices from "../../services/AuthServices";
import { useNavigate } from "react-router-dom";

export default function OTPVerification() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: "",
  });

  const [otp, setOTP] = useState("");

  const query = useQuery();

  const proceedWithOTPHandler = async () => {
    setLoading(true);

    if (otp.length === 6) {
      try {
        const verifyOtp = await AuthServices.verifyOtp(
          otp,
          forgotPasswordData.email
        );
        // setVerificationTokens({
        //   ...verificationTokens,
        //   reset_token: verifyOtp.data.reset_token,
        // });
        toast.success(verifyOtp.message);
        navigate(`/auth/reset-password?email=${forgotPasswordData.email}`);

        setLoading(false);
      } catch (err) {
        toast.error(err.response.data.message);
        setLoading(false);
      }
    } else {
      toast.error('"OTP" cannot be empty');
      setLoading(false);
    }
  };

  useEffect(() => {
    const email = query.get("email");

    if (email) {
      setForgotPasswordData({ email });
    }
  }, [query]);

  return (
    <>
      <div className="flex min-h-screen bg-gray-900 flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex items-center justify-center gap-2">
            <img
              className="h-10 w-auto"
              src="/chat-logo.svg"
              alt="Chatbot-23"
            />
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Forgot password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <OTPInput
                value={otp}
                onChange={setOTP}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                inputStyle={
                  "rounded-md h-[50px] w-[50px] border-0 bg-white/5 text-5xl p-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500"
                }
                containerStyle={"flex w-full gap-3 justify-center"}
                inputType="number"
              />

              <span className="block text-center pb-6 pt-4 font-small leading-6 text-xl text-gray-300">
                Please enter the otp from email
              </span>

              <div>
                <button
                  disabled={loading}
                  onClick={proceedWithOTPHandler}
                  className={classnames(
                    loading
                      ? "mt-4 flex w-full justify-center rounded-md bg-gray-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                      : "mt-4 flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                  )}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
