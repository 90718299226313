import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AuthServices from "../../services/AuthServices";
import classnames from "../../utilities/classnames";

export default function SignUpPage() {
  const [passwordProvided, setPasswordProvided] = useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [signUpData, setSignUpData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    confirm_password: "",
    phone: "",
  });

  const navigate = useNavigate();

  const nextHandler = async () => {
    if (signUpData.email.trim() !== "") {
      if (
        signUpData.first_name.trim() !== "" &&
        signUpData.last_name.trim() !== ""
      ) {
        setPasswordProvided(true);
      } else {
        toast.error("First name and Last name field cannot be empty");
      }
    } else {
      toast.error("Email field cannot be empty");
    }
  };

  const signUpHandler = async () => {
    setLoading(true);

    try {
      if (signUpData.password === "") {
        toast.error("Password field cannot be empty");
        return;
      }
      if (signUpData.confirm_password === "") {
        toast.error("Confirm password field cannot be empty");
        return;
      }

      if (signUpData.password !== signUpData.confirm_password) {
        toast.error("Passwords do not match");
        return;
      }
      if (signUpData.email.trim() === "") {
        toast.error("Email field cannot be empty");
        return;
      }
      if (signUpData.phone.trim() === "") {
        toast.error("Phone number field cannot be empty");
        return;
      }
      if (
        signUpData.first_name.trim() === "" ||
        signUpData.last_name.trim() === ""
      ) {
        toast.error("First name and Last name field cannot be empty");
        return;
      }
      if (
        signUpData.password.trim() === "" ||
        signUpData.confirm_password.trim() === ""
      ) {
        toast.error("Passwords fields cannot be empty");
        return;
      }

      const register = await AuthServices.signUp({
        email: signUpData.email,
        name: signUpData.first_name + " " + signUpData.last_name,
        password: signUpData.password,
        phone: signUpData.phone,
      });

      if (register?.status_code) {
        setLoading(false);
        toast.success(register.message);
        navigate("/auth/sign-in");
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later");
    }
  };

  return (
    <>
      <div className="flex min-h-screen bg-gray-900 flex-1 flex-col justify-center px-6 py-8  lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex items-center justify-center gap-2">
            <img
              className="h-10 w-auto"
              src="/chat-logo.svg"
              alt="Chatbot-23"
            />
          </div>

          <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Sign up with your details
          </h2>

          {/* {!passwordProvided ? (
						<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
							Sign up with your details
						</h2>
					) : (
						<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
							Confirm your password
						</h2>
					)} */}
        </div>

        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-3">
            <Fragment>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={signUpData.email}
                    onChange={(changeEvent) =>
                      setSignUpData({
                        ...signUpData,
                        email: changeEvent.target.value,
                      })
                    }
                    disabled={loading}
                    autoComplete="email"
                    required
                    className={classnames(
                      loading
                        ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    )}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  First name
                </label>
                <div className="mt-1">
                  <input
                    id="first_name"
                    name="first_name"
                    type="first_name"
                    value={signUpData.first_name}
                    onChange={(changeEvent) =>
                      setSignUpData({
                        ...signUpData,
                        first_name: changeEvent.target.value,
                      })
                    }
                    disabled={loading}
                    autoComplete="firstname"
                    required
                    className={classnames(
                      loading
                        ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    )}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    id="last_name"
                    name="last_name"
                    type="last_name"
                    value={signUpData.last_name}
                    onChange={(changeEvent) =>
                      setSignUpData({
                        ...signUpData,
                        last_name: changeEvent.target.value,
                      })
                    }
                    disabled={loading}
                    autoComplete="lastname"
                    required
                    className={classnames(
                      loading
                        ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    )}
                  />
                </div>
              </div>
            </Fragment>

            <Fragment>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Phone
                </label>
                <div className="mt-1">
                  <input
                    id="phone"
                    name="phone"
                    type="phone"
                    value={signUpData.phone}
                    onChange={(changeEvent) =>
                      setSignUpData({
                        ...signUpData,
                        phone: changeEvent.target.value,
                      })
                    }
                    disabled={loading}
                    autoComplete="firstname"
                    required
                    className={classnames(
                      loading
                        ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    )}
                  />
                </div>
              </div>
            </Fragment>

            <Fragment>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Password
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={signUpData.password}
                    onChange={(changeEvent) =>
                      setSignUpData({
                        ...signUpData,
                        password: changeEvent.target.value,
                      })
                    }
                    disabled={loading}
                    autoComplete="current-password"
                    required
                    className={classnames(
                      loading
                        ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    )}
                  />
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Confirm password
                  </label>
                </div>
                <div className="mt-1 mb-7">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={signUpData.confirm_password}
                    onChange={(changeEvent) =>
                      setSignUpData({
                        ...signUpData,
                        confirm_password: changeEvent.target.value,
                      })
                    }
                    disabled={loading}
                    autoComplete="current-password"
                    required
                    className={classnames(
                      loading
                        ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    )}
                  />
                </div>
              </div>
            </Fragment>

            <div>
              <button
                disabled={loading}
                onClick={signUpHandler}
                className={classnames(
                  loading
                    ? "flex w-full justify-center rounded-md bg-gray-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                    : "flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                )}
              >
                Confirm
              </button>
            </div>
          </div>

          <p className="mt-5 text-center text-sm text-gray-400">
            Already a user?{" "}
            <a
              href="/auth/sign-in"
              className="font-semibold leading-6 text-blue-400 hover:text-blue-300"
            >
              Sign in here
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
