import ApiRouter from "../routes/ApiRouter";
import requests from "./http";

const AuthServices = {
  signIn(body) {
    return requests.post(`${ApiRouter.SIGN_IN}`, body);
  },
  signOut(body) {
    return requests.post(`${ApiRouter.SIGN_OUT}`, body);
  },
  signUp(body) {
    return requests.post(`${ApiRouter.SIGN_UP}`, body);
  },
  getCurrentUser() {
    return requests.getx(`${ApiRouter.GET_CURRENT_USER}`);
  },
  googleLogin(token) {
    return requests.post(`${ApiRouter.GOOGLE_LOGIN}`, { token });
  },
  sendOtp(email) {
    return requests.post(`${ApiRouter.SEND_OTP}?email=${email}`);
  },
  verifyOtp(otp, email) {
    return requests.post(`${ApiRouter.VERIFY_OTP}?email=${email}&otp=${otp}`);
  },
  setPassword(email, password) {
    return requests.post(
      `${ApiRouter.SET_PASSWORD}?email=${email}&password=${password}`
    );
  },
};

export default AuthServices;
