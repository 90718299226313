const ApiRouterObject = {
  BASE_URL: `${process.env.REACT_APP_SERVER_BASE_URL}`,
  SIGN_IN: `auth/login`,
  SIGN_OUT: `/auth/signout`,
  SIGN_UP: `/auth/register`,
  GET_CURRENT_USER: `/auth/me`,
  GOOGLE_LOGIN: `/auth/google/authorize`,
  SEND_OTP: `/auth/forgot-password`,
  VERIFY_OTP: `/auth/verify-otp`,
  SET_PASSWORD: `/auth/reset-password`,
};

export default ApiRouterObject;
