import { useEffect, useState } from "react";
import classnames from "../../utilities/classnames";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import useQuery from "../../hooks/useQuery";
import AuthServices from "../../services/AuthServices";
import { Link, useNavigate } from "react-router-dom";

export default function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: "",
  });

  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const email = query.get("email");
    setForgotPasswordData({ email });
  }, [query]);

  const proceedWithEmailHandler = async () => {
    setLoading(true);
    try {
      const sendOtp = await AuthServices.sendOtp(forgotPasswordData.email);

      toast.success(sendOtp.message);

      navigate(`/auth/otp-verification?email=${forgotPasswordData.email}`);

      setLoading(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex min-h-screen bg-gray-900 flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex items-center justify-center gap-2">
            <img
              className="h-10 w-auto"
              src="/chat-logo.svg"
              alt="Chatbot-23"
            />
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Forgot password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            {/* Step 1 */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-white"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={forgotPasswordData.email}
                  onChange={(changeEvent) =>
                    setForgotPasswordData({
                      ...forgotPasswordData,
                      email: changeEvent.target.value,
                    })
                  }
                  disabled={loading}
                  autoComplete="email"
                  required
                  className={classnames(
                    loading
                      ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                      : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  )}
                />
              </div>
              <div className="flex items-center justify-end mt-2 ">
                <div className="text-sm">
                  <Link
                    to={`/auth/sign-in`}
                    className="font-semibold text-blue-400 hover:text-blue-300"
                  >
                    Remembered password? Sign in
                  </Link>
                </div>
              </div>
              <div>
                <button
                  disabled={loading}
                  onClick={proceedWithEmailHandler}
                  className={classnames(
                    loading
                      ? "mt-4 flex w-full justify-center rounded-md bg-gray-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                      : "mt-4 flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                  )}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
