/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import {
  Bars3Icon,
  CpuChipIcon,
  UserCircleIcon,
  MicrophoneIcon,
} from "@heroicons/react/20/solid";
import { useOutletContext } from "react-router-dom";
import ChatService from "../services/ChatServices";
import classnames from "../utilities/classnames";
import { LoaderIcon } from "react-hot-toast";
import VoiceModal from "../components/Modal/VoiceModal";
import InputBox from "../components/InputBox";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import SoundWaveIcon from "../components/SoundWaveIcon";

const savedChats = [];

export default function VoiceAskNissan() {
  const [sidebarOpen, setSidebarOpen] = useOutletContext();
  const scrollRef = useRef(null);
  const [input, setInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transcription, setTranscription] = useState("");
  const [voiceRecognitionActive, setVoiceRecognitionActive] = useState(false);
  const [transcriptStarted, setTranscriptStarted] = useState(false);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation]);

  useEffect(() => {
    setTimeout(() => {
      setConversation((current) => [
        ...current,
        {
          id: current.length + 1,
          content: "Welcome to Voice Chat Bot",
          sender: "bot",
        },
      ]);
    }, 500);
    setTimeout(() => {
      setConversation((current) => [
        ...current,
        {
          id: current.length + 1,
          content: "How can I assist you today?",
          sender: "bot",
        },
      ]);
    }, 1000);
  }, []);

  const commands = [
    {
      command: "reset",
      callback: ({ resetTranscript }) => resetTranscript(),
    },
    {
      command: "shut up",
      callback: () => setTranscription("I wasn't talking."),
    },
    {
      command: "Hello",
      callback: () => setTranscription("Hi there!"),
    },
  ];

  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
  } = useSpeechRecognition({ commands });

  const stopListening = () => {
    SpeechRecognition.stopListening({
      continuous: false,
    });
    setVoiceRecognitionActive(false);
    resetTranscript();
    setInput("");
  };

  const startListening = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: "en-GB",
    });
  };

  const abortListening = () => {
    SpeechRecognition.abortListening();
    setVoiceRecognitionActive(false);
    resetTranscript();
    setInput("");
  };

  useEffect(() => {
    if (listening) {
      setInput(transcript);
    } else {
      setInput("");
    }
  }, [listening, transcript]);


  useEffect(() => {
    let timeoutId;

    const handleSpeechChange = () => {
      const { transcript, interimTranscript } = SpeechRecognition;
      if (transcript || interimTranscript) {
        clearTimeout(timeoutId);
      }
    };

    const timeoutCallback = () => {
      stopListening();
      resetTranscript();
      setVoiceRecognitionActive(false);
      setInput("");
    };

    if (listening) {
      timeoutId = setTimeout(timeoutCallback, 3000);
    }

    SpeechRecognition.onSpeechChange = handleSpeechChange;

    return () => {
      clearTimeout(timeoutId);
      SpeechRecognition.onSpeechChange = null;
    };
  }, [
    listening,
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    stopListening,
    setVoiceRecognitionActive,
  ]);

  useEffect(() => {
    if (finalTranscript) {
      setInput(finalTranscript);
      setLoading(true);

      setConversation((current) => [
        ...current,
        { id: current.length + 1, content: finalTranscript, sender: "user" },
      ]);

      const prompt = { question: finalTranscript };
      setInput("");
      abortListening();


      const sendPrompt = async () => {
        try {
          const result = await ChatService.sendPromptVoiceAskNissan(prompt);
          resetTranscript();
          stopListening();
          setLoading(false);

          if (result) {
            const lines = result.split("\n");
            const fullContent = lines
              .map((line) => line.trim().replace(/^data: /, ""))
              .filter((line) => line !== "")
              .join(" ")
              .replace(/" "/g, "")
              .replace(/^"/, "")
              .replace(/"$/, "")
              .replace(/\\n\\n/g, "\n");

            if (fullContent.trim() !== "") {
              setConversation((current) => [
                ...current,
                {
                  id: current.length + 1,
                  content: fullContent.trim(),
                  sender: "bot",
                },
              ]);
            }
          }
        } catch (error) {
          console.error("Error in service call:", error);
        }
      };
      sendPrompt();
    }
  }, [finalTranscript, resetTranscript, abortListening, stopListening]);
  const toggleVoiceRecognition = () => {
    if (voiceRecognitionActive) {
      stopListening();
      resetTranscript();
    } else {
      startListening();
    }
    setVoiceRecognitionActive(!voiceRecognitionActive);
  };

  useEffect(() => {
    if (transcript && !transcriptStarted) {
      setTranscriptStarted(true);
    }
  }, [transcript, transcriptStarted]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isBlank = input.trim().length === 0;

    if (!isBlank) {
      try {
        setLoading(true);
        setConversation((current) => [
          ...current,
          { id: current.length + 1, content: input, sender: "user" },
        ]);

        const prompt = { question: input };

        setInput("");

        const result = await ChatService.sendPromptVoiceAskNissan(prompt);
        setLoading(false);

        if (result) {
          const lines = result.split("\n");
          const processedLines = lines.map((line) =>
            line
              .trim()
              .replace(/^data: /, "")
              .replace(/" "/g, "")
              .replace(/^"/, "")
              .replace(/"$/, "")
              .replace(/\\n\\n/g, "\n")
          );

          const combinedLine = processedLines.join(" ");

          setConversation((current) => [
            ...current,
            {
              id: current.length + 1,
              content: combinedLine,
              sender: "bot",
            },
          ]);
        }
      } catch (error) {
        console.error("Error in API request:", error);

        setConversation((current) => [
          ...current,
          {
            id: current.length + 1,
            content: "Oops! Something went wrong. Please try again.",
            sender: "bot",
          },
        ]);
      } finally {
        setLoading(false);
      }
    } else {
      setConversation((current) => [
        ...current,
        {
          id: current.length + 1,
          content: "Please enter a message before submitting.",
          sender: "bot",
        },
      ]);
    }
  };

  return (
    <div className="xl:pl-72 h-full w-full fixed inset-y-0 flex flex-col">
      <main className="lg:pr-80 flex flex-col justify-between h-full">
        <div className="flex flex-col overflow-hidden">
          <header className="flex  items-center xl:justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 mr-2 p-2.5 text-white xl:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="text-base font-semibold leading-7 text-white">
              Voice Ask Nissan Chat Bot <br />
              <h5 className="text-gray-600 text-sm">
                This chatbot operates based on the information provided within
                the supplied PDF document.
              </h5>
            </div>
          </header>

          {/* Conversation list */}
          <div className="overflow-auto">
            <ul role="list" className="divide-y divide-white/5">
              {conversation.map((messages) => (
                <li
                  key={messages.id}
                  className={classnames(
                    messages.sender === "bot"
                      ? "relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8"
                      : "relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 bg-white bg-opacity-5"
                  )}
                >
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-start gap-x-3">
                      <div>
                        {messages.sender === "bot" ? (
                          <CpuChipIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <UserCircleIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                        <p className="">
                          {messages.content.split("\n").map((item, i) => (
                            <span key={i}>
                              {item}
                              <br />
                            </span>
                          ))}
                        </p>
                      </h2>
                    </div>
                  </div>
                </li>
              ))}
              {loading && (
                <li
                  key={9999}
                  className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8"
                >
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-start gap-x-3">
                      <div>
                        <CpuChipIcon
                          className="h-5 w-5 text-gray-500"
                          aria-hidden="true"
                        />
                      </div>
                      <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                        <span className="text-gray-600 italic">
                          Generating response...
                        </span>
                      </h2>
                    </div>
                  </div>
                  <div
                    className={classnames(
                      "rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 text-white ring-inset"
                    )}
                  >
                    Loading
                  </div>

                  <LoaderIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </li>
              )}
            </ul>
            <div className="float-left clear-both" ref={scrollRef}></div>
          </div>
        </div>
        <div className="p-4 ">
          <div className="flex items-center gap-x-3 relative">
            {voiceRecognitionActive && !loading && (
              <div className="absolute cursor-pointer -left-1 opacity-70 top-[36px] p-2 transform -translate-x-1/2 bg-gray-300 w-12 h-12 rounded-full circle" />
            )}
            {!loading && (
              <div
                className={`text-2xl cursor-pointer relative w-[40px] text-white rounded-full p-2 top-[40px] transition duration-1000 ease-in-out ${
                  voiceRecognitionActive
                    ? "bg-black"
                    : "bg-red-500 hover:bg-white hover:text-red-500"
                }`}
                onClick={toggleVoiceRecognition}
              >
                <MicrophoneIcon />
              </div>
            )}
            {loading && (
              <div
                className="text-2xl relative w-[40px] text-white rounded-full p-2 top-[40px] bg-red-500 opacity-70"
                style={{ pointerEvents: "none" }}
              >
                <MicrophoneIcon />
              </div>
            )}
          </div>

          <div className={`relative ml-[53px] ${transcriptStarted ? "" : ""}`}>
            <InputBox
              handleSubmit={handleSubmit}
              input={input}
              setInput={setInput}
            />
            {transcriptStarted && voiceRecognitionActive && !loading && (
              <div className="absolute top-1/2 right-0 transform translate-y-[-50%] mr-12 text-gray-500">
                <SoundWaveIcon />
              </div>
            )}
          </div>
        </div>
      </main>
      <aside className="bg-black/10 hidden lg:block lg:fixed lg:bottom-0 lg:right-0 lg:top-0 lg:w-80 lg:overflow-y-auto lg:border-l lg:border-white/5">
        <header className="flex items-center justify-between border-b border-white/5 px-4 sm:px-6 sm:py-6 lg:px:8">
          <h2 className="text-base font-semibold leading-7 text-white">
            Chat history
          </h2>
          <a
            href="#"
            className="text-sm font-semibold leading-6 text-indigo-400"
          >
            Clear all
          </a>
        </header>
        <ul role="list" className="divide-y divide-white/5">
          {savedChats.map((item) => (
            <li key={item.id} className="px-4 py-4 sm:px-6 lg:px-8">
              <div className="flex items-center gap-x-3">
                <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-white">
                  {item.saved_chats.name}
                </h3>
              </div>
              <p className="mt-3 truncate text-sm text-gray-500">
                <span className="text-gray-400">{item.date}</span>
              </p>
            </li>
          ))}
        </ul>
      </aside>
    </div>
  );
}
