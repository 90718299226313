import axios from "axios";
import toast from "react-hot-toast";
import ApiRouterObject from "../routes/ApiRouter";

const instance = axios.create({
  baseURL: `${ApiRouterObject.BASE_URL}`,
  timeout: 500000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((req) => {
  if (localStorage.getItem("authToken")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem("authToken")}`;
  }
  return req;
});

const responseBody = (response) => response?.data;

const errorBody = (error) => {
  if (error.response.data) {
    if (error.response.data?.detail) {
      const errorDetails = error.response.data.detail;

      const errorMessages = errorDetails
        .map(
          (detail) =>
            `Error Type: ${detail.type}. Location: ${detail.loc.join(
              ", "
            )}. Message: ${detail.message}.`
        )
        .join("\n");

      toast.error(errorMessages);
    } else toast.error(error.response.data.message);
  } else {
    error.message && toast.error(error.message);
  }
  return {
    success: false,
  };
};

const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody).catch(errorBody),

  getx: (url) => instance.get(url).then(responseBody).catch(errorBody),

  post: (url, body) =>
    instance.post(url, body).then(responseBody).catch(errorBody),

  put: (url) => instance.put(url).then(responseBody).catch(errorBody),

  patch: (url, body) =>
    instance.patch(url, body).then(responseBody).catch(errorBody),

  delete: (url) => instance.delete(url).then(responseBody).catch(errorBody),
};

export const requestWithoutError = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),
};

export default requests;
