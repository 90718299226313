import React, { createContext } from "react";

export const SideContext = createContext();

export const SideProvider = ({ children }) => {
  const [update, setUpdate] = React.useState(false);
  const changeUpdate = () => {
    setUpdate(!update);
  };
  const value = { update, changeUpdate };
  return <SideContext.Provider value={value}>{children}</SideContext.Provider>;
};
