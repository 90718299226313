import React from "react";
import { MicrophoneIcon, XMarkIcon } from "@heroicons/react/20/solid";

export default function VoiceModal({
  isOpen,
  onClose,
  onVoiceRecognitionToggle,
  transcription,
  voiceRecognitionActive,
  stopListening,
}) {
  return (
    isOpen && (
      <div className=" flex top-0  items-center justify-center bg-gray-800 bg-opacity-75">
        <div className="bg-white absolute top-0 h-96 w-96  max-w-screen-sm p-4 rounded-lg">
          <p className="text-sm font-medium text-gray-900 dark:text-white">
           {!voiceRecognitionActive ? " Speak now." : "Not Listening"}
          </p>
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Transcription: {transcription}
          </p>

          {!voiceRecognitionActive ? (
            <div className=" w-14 h-14 left-[150px] top-[70px] items-center justify-center relative rounded-full">
              <div className="absolute w-20 h-20 -left-2 -top-2 rounded-full border-8 bg-gray-300 opacity-70 circle"></div>
              <MicrophoneIcon
                className={`text-4xl cursor-pointer absolute -translate-x-1/2 left-[55%] top-[55%] -translate-y-1/2  text-white bg-red-500 w-12 rounded-full p-2`}
                onClick={onVoiceRecognitionToggle}
              />
            </div>
          ) : (
            <div className="text-center">
              <MicrophoneIcon
                className={`text-4xl cursor-pointer items-center left-[158px] top-[77px] justify-center relative text-black bg-gray-400 w-12 rounded-full p-2`}
                onClick={onVoiceRecognitionToggle}
              />
              <p className="text-center text-sm font-medium text-gray-900 dark:text-white">
                Tap microphone to try again
              </p>
            </div>
          )}
          <XMarkIcon
            className="text-4xl cursor-pointer text-gray-400 absolute hover:text-gray-900 hover:bg-gray- w-8 rounded-full right-1 top-1 transition duration-1000 ease-in-out"
            onClick={() => {
              onClose();
              stopListening();
            }}
          />
        </div>
      </div>
    )
  );
}
