/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import {
  Bars3Icon,
  CpuChipIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import InputBox from "../components/InputBox";
import { Link, useOutletContext } from "react-router-dom";
import ChatService from "../services/ChatServices";
import classnames from "../utilities/classnames";
import { LoaderIcon } from "react-hot-toast";
import Typewriter from "typewriter-effect";

const savedChats = [
  // {
  // 	id: 1,
  // 	saved_chats: {
  // 		name: "Sending whatsapp message",
  // 	},
  // 	date: "Few mins ago",
  // },
];

export default function Bot() {
  const [sidebarOpen, setSidebarOpen] = useOutletContext();
  const scrollRef = useRef(null);
  const [input, setInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation]);

  useEffect(() => {
    setTimeout(() => {
      setConversation((current) => [
        ...current,
        {
          id: current.length + 1,
          content: "Hi there!!!",
          sender: "bot",
        },
      ]);
    }, 500);
    setTimeout(() => {
      setConversation((current) => [
        ...current,
        {
          id: current.length + 1,
          content: "How can I assist you today?",
          sender: "bot",
        },
      ]);
    }, 1000);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isBlank = input.trim().length === 0;

    if (!isBlank) {
      setLoading(true);

      setConversation((current) => [
        ...current,
        { id: current.length + 1, content: input, sender: "user" },
      ]);
      const prompt = { prompt: input };

      setInput("");
      const result = await ChatService.sendPromptAskYourPDF(prompt);

      setLoading(false);

      setConversation((current) => [
        ...current,
        {
          id: current.length + 1,
          content: result.response,
          sender: "bot",
        },
      ]);
    }
  };
  return (
    <div className="xl:pl-72 h-full w-full fixed inset-y-0 flex flex-col">
      <main className="lg:pr-80 flex flex-col justify-between h-full">
        <div className="flex flex-col overflow-hidden">
          <header className="flex items-center xl:justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 mr-2 p-2.5 text-white xl:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button>
            <h1 className="text-base font-semibold leading-7 text-white">
              Ask your PDF
            </h1>
          </header>

          {/* Conversation list */}
          <div className="overflow-auto">
            <ul className="divide-y divide-white/5">
              {conversation.map((messages) => (
                <li
                  key={messages.id}
                  className={classnames(
                    messages.sender === "bot"
                      ? "relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8"
                      : "relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 bg-white bg-opacity-5"
                  )}
                >
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-start gap-x-3">
                      <div>
                        {messages.sender === "bot" ? (
                          <CpuChipIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <UserCircleIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                        {/* {messages.sender === "user" && ( */}
                        <p className="">
                          {messages.content?.split("\n").map((item, i) => (
                            <span key={i}>
                              {item}
                              <br />
                            </span>
                          ))}
                        </p>
                        {/* )} */}
                        {/* {messages.sender === "bot" && (
													<Typewriter
														onInit={(typewriter) => {
															typewriter.typeString(messages.content).start();
														}}
														options={{ delay: 5, cursor: " " }}
													/>
												)} */}
                        {/* <span className="absolute inset-0" /> */}
                      </h2>
                    </div>
                  </div>
                </li>
              ))}
              {loading && (
                <li
                  key={9999}
                  className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8"
                >
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-start gap-x-3">
                      <div>
                        <CpuChipIcon
                          className="h-5 w-5 text-gray-500"
                          aria-hidden="true"
                        />
                      </div>
                      <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                        <span className="text-gray-600 italic">
                          Generating response...
                        </span>
                        {/* <span className="absolute inset-0" /> */}
                      </h2>
                    </div>
                  </div>
                  <div
                    className={classnames(
                      "rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 text-white ring-inset"
                    )}
                  >
                    Loading
                  </div>

                  <LoaderIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </li>
              )}
            </ul>
            <div className="float-left clear-both" ref={scrollRef}></div>
          </div>
        </div>
        <div className="p-4">
          <InputBox
            handleSubmit={handleSubmit}
            input={input}
            setInput={setInput}
          />
        </div>
      </main>

      {/* Activity feed */}
      <aside className="bg-black/10 hidden lg:block lg:fixed lg:bottom-0 lg:right-0 lg:top-0 lg:w-80 lg:overflow-y-auto lg:border-l lg:border-white/5">
        <header className="flex items-center justify-between border-b border-white/5 px-4 sm:px-6 sm:py-6 lg:px-8">
          <h2 className="text-base font-semibold leading-7 text-white">
            Chat history
          </h2>
          <Link
            to="#"
            className="text-sm font-semibold leading-6 text-indigo-400"
          >
            Clear all
          </Link>
        </header>
        <ul role="list" className="divide-y divide-white/5">
          {savedChats.map((item) => (
            <li key={item.id} className="px-4 py-4 sm:px-6 lg:px-8">
              <div className="flex items-center gap-x-3">
                <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-white">
                  {item.saved_chats.name}
                </h3>
              </div>
              <p className="mt-3 truncate text-sm text-gray-500">
                <span className="text-gray-400">{item.date}</span>
              </p>
            </li>
          ))}
        </ul>
      </aside>
    </div>
  );
}
