import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import BrowserRouterObject from "./routes/BrowserRouter";
import { Toaster } from "react-hot-toast";
import { UserProvider } from "./contexts/UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { SWRConfig } from "swr";
import axios from "axios";
import { SideProvider } from "./contexts/SideContext";

const fetcher = async (url) => {
  const session = localStorage.getItem("authToken");

  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_BASE_URL}${url}`,
    {
      headers: {
        Authorization: `Bearer ${session}`,
      },
    }
  );

  return response.data;
};

const router = createBrowserRouter(BrowserRouterObject);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SWRConfig value={{ fetcher }}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <SideProvider>
        <UserProvider>
          <React.Fragment>
            <Toaster />
            <RouterProvider router={router} />
          </React.Fragment>
        </UserProvider>
      </SideProvider>
    </GoogleOAuthProvider>
  </SWRConfig>
);
