import { useEffect, useState } from "react";
import classnames from "../../utilities/classnames";
import toast from "react-hot-toast";
import useQuery from "../../hooks/useQuery";
import AuthServices from "../../services/AuthServices";
import { Link, useNavigate } from "react-router-dom";

export default function ResetPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: "",
  });
  const [newPasswordData, setNewPasswordData] = useState({
    new_password: "",
    confirm_new_password: "",
  });

  const query = useQuery();
  const navigate = useNavigate();

  const proceedWithDataHandler = async () => {
    setLoading(true);
    if (
      newPasswordData.new_password.trim() !==
      newPasswordData.confirm_new_password.trim()
    ) {
      toast.error("Passwords do not match");
      setLoading(false);
      return;
    }

    // if (
    //   newPasswordData.new_password.trim() !== "" &&
    //   newPasswordData.confirm_new_password.trim() !== ""
    // ) {
    try {
      const setPassword = await AuthServices.setPassword(
        forgotPasswordData.email,
        newPasswordData.new_password
        // newPasswordData.confirm_new_password,
        // verificationTokens.reset_token
      );
      toast.success(setPassword.message);

      navigate("/");
    } catch (err) {
      toast.error(err.response.data.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    const email = query.get("email");

    if (email) {
      setForgotPasswordData({ email });
    }
  }, [query]);

  return (
    <>
      <div className="flex min-h-screen bg-gray-900 flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex items-center justify-center gap-2">
            <img
              className="h-10 w-auto"
              src="/chat-logo.svg"
              alt="Chatbot-23"
            />
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Forgot password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="new-password"
                className="block text-sm font-medium leading-6 text-white"
              >
                New password
              </label>
              <div className="mt-2">
                <input
                  id="new-password"
                  name="new-password"
                  type="new-password"
                  value={newPasswordData.new_password}
                  onChange={(changeEvent) =>
                    setNewPasswordData({
                      ...newPasswordData,
                      new_password: changeEvent.target.value,
                    })
                  }
                  disabled={loading}
                  autoComplete="new-password"
                  required
                  className={classnames(
                    loading
                      ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                      : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  )}
                />
              </div>
              <label
                htmlFor="confirm-new-password"
                className="mt-2 block text-sm font-medium leading-6 text-white"
              >
                Confirm new password
              </label>
              <div className="mt-2">
                <input
                  id="confirm-new-password"
                  name="confirm-new-password"
                  type="confirm-new-password"
                  value={newPasswordData.confirm_new_password}
                  onChange={(changeEvent) =>
                    setNewPasswordData({
                      ...newPasswordData,
                      confirm_new_password: changeEvent.target.value,
                    })
                  }
                  disabled={loading}
                  autoComplete="confirm-new-password"
                  required
                  className={classnames(
                    loading
                      ? "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                      : "block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  )}
                />
              </div>
              <div>
                <button
                  disabled={loading}
                  onClick={proceedWithDataHandler}
                  className={classnames(
                    loading
                      ? "mt-4 flex w-full justify-center rounded-md bg-gray-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                      : "mt-4 flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                  )}
                >
                  Confirm & Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
