import { useContext } from "react";
import App from "../App";
import Bot from "../outlets/Bot";
import MediChatbotOutlet from "../outlets/MediChatbotOutlet";
import ForgotPasswordPage from "../pages/authentication/forgot-password";
import SignInPage from "../pages/authentication/sign-in";
import SignUpPage from "../pages/authentication/sign-up";
import ErrorPage from "../pages/error-page";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import GoogleAuthorizationPage from "../pages/authorization/google";
import IABChatbotOutlet from "../outlets/IABChatbotOutlet";
import IABBanglaChatbotOutlate from "../outlets/IABBanglaChatbotOutlate";
import IABChatbotOutletEnglish from "../outlets/IABChatbotOutletEnglish";
import CMCChatbotOutletEnglish from "../outlets/CMCChatbotOutletEnglish";
import VoiceAskNissan from "../outlets/VoiceAskNissan";

import BotOutlet from "../outlets/BotOutlet";
import OTPVerification from "../pages/authentication/otp-verification";
import ResetPasswordPage from "../pages/authentication/reset-password";

export const ProtectedRoute = ({ children }) => {
  const { state } = useContext(UserContext);
  if (state?.userInfo?.email) {
    return children;
  } else {
    return <Navigate to="/auth/sign-in" replace />;
  }
};

export const AuthRoutes = ({ children }) => {
  const { state } = useContext(UserContext);
  if (!state?.userInfo?.email) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

const BrowserRouterObject = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Navigate to="/general-bot" replace />,
      },
      {
        path: "/general-bot",
        element: (
          <ProtectedRoute>
            <Bot />
          </ProtectedRoute>
        ),
      },
      {
        path: "/bots/:id",
        element: (
          <ProtectedRoute>
            <BotOutlet />
          </ProtectedRoute>
        ),
      },
      {
        path: "/bots/medichat",
        element: (
          <ProtectedRoute>
            <MediChatbotOutlet />
          </ProtectedRoute>
        ),
      },
      {
        path: "/bots/iabchat",
        element: (
          <ProtectedRoute>
            <IABChatbotOutlet />
          </ProtectedRoute>
        ),
      },
      {
        path: "/bots/iabchat-bangla",
        element: (
          <ProtectedRoute>
            <IABBanglaChatbotOutlate />
          </ProtectedRoute>
        ),
      },
      {
        path: "/bots/iabchat-english-v2",
        element: (
          <ProtectedRoute>
            <IABChatbotOutletEnglish />
          </ProtectedRoute>
        ),
      },
      {
        path: "/bots/cmcchat",
        element: (
          <ProtectedRoute>
            <CMCChatbotOutletEnglish />
          </ProtectedRoute>
        ),
      },
      {
        path: "/bots/vochat",
        element: (
          <ProtectedRoute>
            <VoiceAskNissan />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "/auth/sign-in",
        element: (
          <AuthRoutes>
            <SignInPage />
          </AuthRoutes>
        ),
      },
      {
        path: "/auth/sign-up",
        element: (
          <AuthRoutes>
            <SignUpPage />
          </AuthRoutes>
        ),
      },
      {
        path: "/auth/forgot-password",
        element: (
          <AuthRoutes>
            <ForgotPasswordPage />
          </AuthRoutes>
        ),
      },
      {
        path: "/auth/otp-verification",
        element: (
          <AuthRoutes>
            <OTPVerification />
          </AuthRoutes>
        ),
      },
      {
        path: "/auth/reset-password",
        element: (
          <AuthRoutes>
            <ResetPasswordPage />
          </AuthRoutes>
        ),
      },

      {
        path: "/auth/google",
        element: (
          <AuthRoutes>
            <GoogleAuthorizationPage />
          </AuthRoutes>
        ),
      },
    ],
  },
];

export default BrowserRouterObject;
